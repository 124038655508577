
.sidebar{
  width: 250px;
  min-width: 250px;
  height: 100vh;
  padding: 20px 20px;
  margin: 0;
  flex: 0 0 auto;
  box-shadow: 0 0 6px hsl(210 14% 90%);
}
